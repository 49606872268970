<template>
	<div class="reset">
		<div class="tit">
			{{i18n('18')}}
		</div>
		<div class="inpWap">
			<div class="inputStyle">
				<div class="input">
					<div class="left">
						{{i18n('218')}}
					</div>
					<div class="center">
						<input type="text" v-model="pass" :placeholder="i18n('219')" v-if="passType=='text'"
							@blur="pasChange($event,'pass')">
						<input type="password" v-model="pass" :placeholder="i18n('219')" v-else
							@blur="pasChange($event,'pass')">
					</div>
					<div class="right" style="cursor: pointer;">
						<img src="@/assets/resetPassword/06.png" alt="" @click="passTypeChange">
					</div>
				</div>
				<div class="errorText">
					<span v-if="passVer==3">{{i18n('220')}}</span>
				</div>
			</div>
			<div class="inputStyle">
				<div class="input">
					<div class="left">
						{{i18n('221')}}
					</div>
					<div class="center">
						<input type="text" v-model="passTwo" :placeholder="i18n('219')" v-if="passTwoType=='text'"
							@blur="pasChange($event,'passTwo')">
						<input type="password" v-model="passTwo" :placeholder="i18n('219')" v-else
							@blur="pasChange($event,'passTwo')">
					</div>
					<div class="right" style="cursor: pointer;">
						<img src="@/assets/resetPassword/06.png" alt="" @click="passTwoTypeChange">
					</div>
				</div>
				<div class="errorText">
					<span v-if="passTwoVer==3">{{i18n('222')}}</span>
				</div>
			</div>
		</div>
		<div class="but" @click='restPassword'>
			{{i18n('56')}}
		</div>
	</div>
</template>

<script>
	import {
		restPassword
	} from "@/api/user.js"
	export default {
		name: "reset",
		props: ['check_code'],
		data() {
			return {
				pass: '',
				passTwo: '',
				passVer: 1,
				passTwoVer: 1,
				passType: 'text',
				passTwoType: 'text',
				loding:false
			}
		},
		computed: {
			lang() {
				return this.$store.state.i18n.lang
			}
		},
		methods: {
			async restPassword() {
				if (this.check_code != 0 && this.pass != '') {
					console.log(this.loding)
					if(!this.loding){
						this.loding = true
						try{
							await restPassword({
								check_code: this.check_code,
								pass: this.pass
							})
							this.$emit("restSuccess")
						}catch(e){
							this.loding = false
						}
					}
					
				}
			},
			pasChange(e, type) {
				if (type == 'pass') {
					if (this.pass == '') {
						this.passVer = 3
					} else {
						this.passVer = 2
					}
					if (this.pass != '' && this.passTwo != '') {
						if (this.pass == this.passTwo) {
							this.passTwoVer = 2
						} else {
							this.passTwoVer = 3
						}
					}
				}
				if (type == 'passTwo') {
					if (this.pass == '') {
						this.passVer = 3
					} else if (this.pass == this.passTwo) {
						this.passTwoVer = 2
					} else {
						this.passTwoVer = 3
					}
				}
			},
			passTypeChange() {
				if (this.passType == 'text') {
					this.passType = 'pass'
				} else {
					this.passType = 'text'
				}
			},
			passTwoTypeChange() {
				if (this.passTwoType == 'text') {
					this.passTwoType = 'pass'
				} else {
					this.passTwoType = 'text'
				}
			}
		},
		created() {

		},
		mounted() {

		}
	};
</script>

<style scoped>
	.inputStyle {
		width: 100%;
	}

	.input {
		width: 100%;
		height: 44px;
		border: 1px solid rgba(170, 181, 195, 1);
		border-radius: 24px;
		display: flex;
		align-items: center;
		padding: 0 20px 0 16px;
		box-sizing: border-box;
	}

	.inputError {
		border: 1px solid rgba(242, 55, 37, 1);
	}

	.errorText {
		font-family: PingFangSC-Medium;
		font-size: 14px;
		color: #F23725;
		font-weight: 500;
		padding-left: 15px;
		padding-top: 4px;
		height: 30px;
		box-sizing: border-box;
	}

	.input .left {
		width: 60px;
		white-space: nowrap;
		font-family: PingFangSC-Medium;
		font-size: 16px;
		color: #1D2129;
		font-weight: 500;
	}

	.input .center {
		flex: 1;
	}

	.input .center input {
		width: 100%;
		line-height: 44px;
		height: 44px;
		font-family: PingFangSC-Regular;
		font-size: 16px;
		color: #1D2129;
		font-weight: 400;
	}

	.input .right img {
		width: 16px;
		height: 16px;
		display: block;
	}

	.reset {
		width: 400px;
		height: 369px;
		background: #FFFFFF;
		border-radius: 20px;
		margin: 0 auto;
		margin-top: 25px;
	}

	.tit {
		font-family: PingFangSC-Medium;
		font-size: 24px;
		color: #1D2129;
		text-align: center;
		font-weight: 500;
		padding: 30px 0 48px 0;
	}

	.inpWap {
		width: 310px;
		margin: 0 auto;
		margin-bottom: 20px;
	}

	.but {
		width: 310px;
		height: 44px;
		background: #56AB7B;
		border-radius: 22.5px;
		margin: 0 auto;
		text-align: center;
		line-height: 44px;
		font-family: PingFangSC-Medium;
		font-size: 20px;
		color: #FFFFFF;
		text-align: center;
		font-weight: 500;
		cursor: pointer;
	}
</style>
