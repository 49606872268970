<template>
	<div class="resetPassword">
		<div class="one">
			<img src="@/assets/log.png" alt="">
			<div>{{i18n('02')}}</div>
		</div>
		<div class="twoThree">
			<div class="two">
				<div class="bz">
					<div class="top">
						<div :class="type==1?'item itemAc':'item'">
							<div>1</div>
						</div>
						<img src="@/assets/resetPassword/05.png" alt="">
						<div :class="type==2?'item itemAc':'item'">
							<div>2</div>
						</div>
						<img src="@/assets/resetPassword/05.png" alt="">
						<div :class="type==3?'item itemAc':'item'">
							<div>3</div>
						</div>
					</div>
					<div class="bot">
						<div :class="type==1?'ac':''">{{i18n('226')}}</div>
						<div :class="type==2?'ac':''">{{i18n('18')}}</div>
						<div :class="type==3?'ac':''">{{i18n('227')}}</div>
					</div>
				</div>
				<veriPhone @checkPhoneSuccess="checkPhoneSuccess" v-if="type==1"></veriPhone>
				<reset :check_code="check_code" v-if="type==2" @restSuccess="type=3"></reset>
				<succ v-if="type==3"></succ>
			</div>
			<div class="three">
				<img src="@/assets/loginRegistration/ios.png" alt="">
				<img src="@/assets/loginRegistration/android.png" alt="">
			</div>
		</div>

		<div class="four">
			<div>
				<img src="@/assets/resetPassword/01.png" alt="">{{i18n('146')}}
			</div>
			<div>
				<img src="@/assets/resetPassword/02.png" alt="">{{i18n('147')}}
			</div>
			<div>
				<img src="@/assets/resetPassword/03.png" alt="">{{i18n('148')}}
			</div>
			<div>
				<img src="@/assets/resetPassword/04.png" alt="">{{i18n('149')}}
			</div>
		</div>
		<foot></foot>
	</div>
</template>

<script>
	import veriPhone from "./components/veriPhone.vue"
	import reset from "./components/reset.vue"
	import succ from "./components/succ.vue"
	import foot from "@/components/topBot/footer.vue"
	export default {
		name: "resetPassword",
		components: {
			veriPhone,
			reset,
			succ,
			foot
		},
		data() {
			return {
				type: 1,
				check_code: 0
			}
		},
		methods: {
			checkPhoneSuccess(check_code) {
				this.check_code = check_code
				this.type = 2
			}
		},
		destroyed() {
			this.$store.commit('user/setHeadShow', true)
		},
		created() {
			this.$store.commit('user/setHeadShow', false)
		},
		mounted() {
			this.$store.commit('user/setHeadShow', false)
		}
	};
</script>

<style scoped>
	.bz .top {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.bz .top .item {
		width: 44px;
		height: 44px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.bz .top .itemAc {
		background: rgba(86, 171, 123, .23);
	}

	.bz .top .item div {
		width: 32px;
		height: 32px;
		background: #4E5969;
		font-family: PingFangSC-Medium;
		font-size: 22px;
		color: #FFFFFF;
		text-align: center;
		font-weight: 500;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
	}

	.bz .top .itemAc div {
		background: #56AB7B;
	}

	.bz .top img {
		width: 100px;
		display: block;
		margin: 0 12px;
	}

	.bz .bot {
		display: flex;
		align-items: center;
		justify-content: center;
		font-family: PingFangSC-Regular;
		font-size: 16px;
		color: #86909C;
		text-align: center;
		font-weight: 400;
		margin-top: 4px;
	}

	.bz .bot div {
		width: 168px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.bz .bot div.ac {
		color: #56AB7B;
	}

	.twoThree {
		background: #efefef;
		padding: 18px 0 54px 0;
	}

	.four {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100px;
	}

	.four div {
		display: flex;
		align-items: center;
		font-family: PingFangSC-Medium;
		font-size: 16px;
		color: #017E3D;
		text-align: center;
		font-weight: 500;
		margin-right: 176px;
	}

	.four div img {
		margin-right: 17px;
		width: 36px;
		height: 36px;
		display: block;
	}

	.two {
		margin: 0 auto;
		margin-bottom: 28px;
	}

	.three {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.three img {
		width: 148.71px;
		height: 46px;
		display: block;
		margin-right: 25px;
	}

	.one {
		width: 100%;
		/* width: 1920px; */
		/* width: 1440px; */
		height: 156px;
		background: #56AB7B;
		border: 1px solid rgba(151, 151, 151, 1);
		padding: 32px 170px 0 120px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		box-sizing: border-box;
	}

	.one img {
		/* width: 147px; */
		height: 58px;
		display: block;
	}

	.one div {
		font-size: 18px;
		color: #FFFFFF;
		font-weight: 400;
	}
</style>
