<template>
	<div class="succ">
		<img src="@/assets/resetPassword/success.png" alt="">
		<div class="text">{{i18n('223')}}</div>
		<div class="but" @click="$router.replace('/loginRegistration?type=1')">
			{{i18n('56')}}
		</div>
	</div>
</template>

<script>
	export default {
		name: "succ",
		data() {
			return {
				
			}
		},
		methods: {
			restPassword() {
				
			},
		
		},
		created() {

		},
		mounted() {

		}
	};
</script>

<style scoped>
	.succ{
		width: 400px;
		height: 369px;
		background: #FFFFFF;
		border-radius: 20px;
		margin: 0 auto;
		margin-top: 25px;
	}
	img{
		width: 100px;
		height: 100px;
		display: block;
		padding-top: 62px;
		padding-bottom: 14px;
		margin: 0 auto;
	}
	.text{
		font-family: PingFangSC-Medium;
		font-size: 20px;
		color: #1D2129;
		text-align: center;
		font-weight: 500;
		margin-bottom: 75px;
	}
	.but{
		width: 310px;
		height: 44px;
		background: #56AB7B;
		border-radius: 22.5px;
		margin: 0 auto;
		text-align: center;
		line-height: 44px;
		font-family: PingFangSC-Medium;
		font-size: 20px;
		color: #FFFFFF;
		text-align: center;
		font-weight: 500;
		cursor: pointer;
	}
</style>
