<template>
	<div class="veriPhone">
		<div class="tit">
			{{i18n('224')}}
		</div>
		<div class="inpWap">
			<inputStyle v-for="(item,index) in inputData" :key="index" :label='item.label' :type='item.type'
				:keyType='item.keyType' :verification='item.verification' :errorText='item.errorText'
				:keyValue="item.value" :codeText="codeText" @getCode="getCode" :keyName='item.keyName'
				:placeholder="item.placeholder" :value='item.value' @change="change($event,index)"></inputStyle>
		</div>
		<div class="but" @click='checkPhone'>
			{{i18n('225')}}
		</div>
	</div>
</template>

<script>
	import {
		sendCode,
		checkPhone
	} from "@/api/user.js"
	import inputStyle from '@/components/inputStyle.vue'
	export default {
		name: "veriPhone",
		components: {
			inputStyle
		},
		data() {
			return {
				inputData: [{
						label: this.i18n('16'),
						type: 'number',
						keyType: 'phone',
						verification: 1,
						placeholderLang: '04',
						errorTextLang: '03',
						labelLang: '16',
						errorText: this.i18n('03'),
						placeholder: this.i18n('04'),
						keyName: 'phone',
						value: ''
					},
					{
						label: this.i18n('10'),
						type: 'number',
						keyType: 'code',
						verification: 1,
						placeholderLang: '06',
						errorTextLang: '05',
						labelLang: '10',
						errorText: this.i18n('05'),
						placeholder: this.i18n('06'),
						keyName: 'code',
						value: '',
					}
				],
				time: 0,
				interval: false,
				codeText: this.i18n('09')
			}
		},
		computed: {
			lang() {
				return this.$store.state.i18n.lang
			}
		},
		watch: {
			lang() {
				this.inputData.phone.map(item => {
					item.errorText = this.i18n(item.errorTextLang)
					item.placeholder = this.i18n(item.placeholderLang)
					item.label = this.i18n(item.labelLang)
				})
				this.inputData.paw.map(item => {
					item.errorText = this.i18n(item.errorTextLang)
					item.placeholder = this.i18n(item.placeholderLang)
					item.label = this.i18n(item.labelLang)
				})
			}
		},
		methods: {
			async checkPhone() {
				let params = {

				}
				let verification = true
				this.inputData.map(item => {
					if (item.verification == 2) {
						params[item.keyName] = item.value
					} else {
						item.verification = 3
						verification = false
					}
				})
				if (!verification) {
					return false
				}
				let {
					check_code
				} = await checkPhone(params)
				this.$emit('checkPhoneSuccess', check_code)
			},
			change(e, index) {
				this.inputData[index] = Object.assign(this.inputData[index], e)
			},
			async getCode() {
				if (this.time != 0) {
					return false
				}
				let verification = 1
				let phone = 1
				this.inputData.map(item => {
					if (item.keyName == 'phone') {
						verification = item.verification
						phone = item.value
					}
				})
				if (verification == 2) {
					await sendCode({
						phone,
						type: 1
					})
					clearInterval(this.interval);
					this.time = 60;
					this.yzmText = this.time + 's';
					this.interval = setInterval(() => {
						if (this.time > 0) {
							this.time--
							this.codeText = this.time + 's';
						} else {
							this.time = 0
							this.codeText = this.i18n('09');
							clearInterval(this.interval);
						}
					}, 1000)
				}
			},
		},
		created() {

		},
		mounted() {

		}
	};
</script>

<style scoped>
	.veriPhone {
		width: 400px;
		height: 369px;
		background: #FFFFFF;
		border-radius: 20px;
		margin: 0 auto;
		margin-top: 25px;
	}

	.tit {
		font-family: PingFangSC-Medium;
		font-size: 24px;
		color: #1D2129;
		text-align: center;
		font-weight: 500;
		padding: 30px 0 48px 0;
	}

	.inpWap {
		width: 310px;
		margin: 0 auto;
		margin-bottom: 20px;
	}

	.but {
		width: 310px;
		height: 44px;
		background: #56AB7B;
		border-radius: 22.5px;
		margin: 0 auto;
		text-align: center;
		line-height: 44px;
		font-family: PingFangSC-Medium;
		font-size: 20px;
		color: #FFFFFF;
		text-align: center;
		font-weight: 500;
		cursor: pointer;
	}
</style>
